import React from 'react';
import {Helmet} from "react-helmet"
import {useStaticQuery,graphql} from "gatsby"


const SEO = ({description,lang,meta,keywords,title}) => {

    const {site}=useStaticQuery(
        graphql`
            query{
                site{
                    siteMetadata{
                        title
                        description
                        author
                        keywords
                        siteUrl 
                    }
                }
            }

        `
    )


    const metaDescription=description || site.siteMetadata.description
    const metaTitle=title || site.siteMetadata.title
    const metaKeywords=keywords || site.siteMetadata.keywords
    console.log(metaKeywords)
    return (
        <Helmet
        
        title={metaTitle}
        htmlAttributes={{
            lang,
        }}
        meta={[
            {
                name:"title",
                content:metaTitle
            },
            {
                name:"keywords",
                content:metaKeywords
            },
            {
                name:"description",
                content:metaDescription
            }
            
        ]}
        >
         <script type="application/ld+json">
    {`
        {
          "@context": "https://www.schema.org",
          "@type": "Blog",
          "url": "https://www.anonycoder.com",
          "name": "Anonycoder"
        }
      `}
  </script>   
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
    keywords:``,
    title:``
  }
 
export default SEO;