import React from 'react';
import footerStyles from "./footer.module.scss"
import Link from "gatsby-link"


const Footer = () => {
   
    
    return ( 
        <footer className={footerStyles.footer}>
            <p style={{fontSize:"120%"}}>It could be anyone</p>
            <p ><Link style={{fontSize:"80%",color:"#777777",textDecoration:"none"}} to="/sitemap.xml">Sitemap</Link></p>
        </footer>



     );
}
 
export default Footer;