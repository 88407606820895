module.exports = {
  siteMetadata:{
    title:"Anonycoder",
    author:"The Anonycoder",
    description:"A website for learning programming in a variety of languages and environments. Anonycoder is written for beginners and experts alike. A coder can be anyone.",
    siteUrl:"https://www.anonycoder.com",
    keywords:"programming, python, coding, coder, how-to, programmer, software, tech"
  },
  plugins: [
    {
      resolve:'gatsby-plugin-google-adsense',
      options:{
        publisherId:'ca-pub-7334468663543715'
      }
    },
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        trackingIds: [
          "G-9WX22GRK6V"
        ],
      },
    },
    'gatsby-plugin-sitemap',
    'gatsby-plugin-sass',
    `gatsby-transformer-sharp`, 
    `gatsby-plugin-sharp`,
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-offline',
    {
      resolve:"gatsby-source-filesystem",
      options:{
        name:"src",
        path:`${__dirname}/src/`
      }
    },
    'gatsby-plugin-sharp',
    {
      resolve:'gatsby-transformer-remark',
      options:{
        plugins:[
          'gatsby-remark-relative-images',
          {
            resolve:"gatsby-remark-images",
            options:{
              maxWidth: 750,
              linkImagesToOriginal: false
            }
          }
        ]
      }
    }
  ]
}
