import React from 'react';
import Link from "gatsby-link"
import headerStyles from "./header.module.scss"
import { siteMetadata } from '../../gatsby-config';
import Img from "gatsby-image"
import {graphql,useStaticQuery} from "gatsby"

 

const Header = () => {
  const query = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "images/anon4.jpg" }) {
      childImageSharp {
        # Specify a fixed image and fragment.
        # The default width is 400 pixels
        fluid{
          ...GatsbyImageSharpFluid

        }
      }
    }
  }
`)

console.log(query)


  

    return ( 
        <header className={headerStyles.header}>
            <nav style={{background:"black",padding:"0"}} className="navbar navbar-expand-lg navbar-dark ">
  <Link style={{marginRight:0}} className="navbar-brand" to="/"><div className="container-fluid" style={{padding:"0"}}><div className="col"><Img  fluid={query.file.childImageSharp.fluid} className={headerStyles.titleImage} alt="Logo"/></div><div className="col"><h1 className={headerStyles.title}>{siteMetadata.title}</h1><p className={headerStyles.subtitle}>It can be anyone</p></div></div></Link>
  <button  className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span  className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="navbarNav">
    <ul  className="navbar-nav">
      
      <li className="nav-item">
        <Link className={headerStyles.navLink} href="/blog">Blog</Link>
      </li>
      <li className="nav-item">
        <Link className={headerStyles.navLink} to="/contact">Contact</Link>
      </li>
    </ul>
  </div>
</nav>
        </header>
     );
}
 
export default Header;